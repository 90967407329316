import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { disconnect, setAddress, setBalance, setSignature } from "../actions/authActions"
import { connectWallet, disconnectWallet, getBalance, requestSignature, withdrawBalance } from "../controllers/wallet"
import config from "../data/config.json"
import { truncateText } from "../utils/uiUtils"


async function handleWalletConnection(dispatch, connected) {
    if (connected) {
        disconnectWallet()
        dispatch(disconnect())
    } else {
        const address = await connectWallet(true)
        if (address) {
            const signature = await requestSignature(address)
            if (signature) {
                dispatch(setAddress(address))
                dispatch(setSignature(signature))
                updateBalance(address, dispatch)
            }
        }
    }
}

async function updateBalance(address, dispatch, connected) {
    if (connected) {
        const balance = await getBalance(address)
        dispatch(setBalance(balance))
    }
}

const Home = () => {
    const dispatch = useDispatch()
    const connected = useSelector((state) => state.auth.connected)
    const balance = useSelector((state) => state.auth.balance)
    const address = useSelector((state) => state.auth.address)

    useEffect(() => {
        updateBalance(address, dispatch, connected)
    })
    return (
        <div>
            <ToastContainer />
            {config.logo && <img className="dAppLogo" src={config.logo} />}
            {config.title && <h2 className="dAppTitle">{config.title}</h2>}
            {config.description && <p className="dAppDescription">{config.description}</p>}
            {address && <p className="address">{truncateText(address, 5, 5)}</p>}
            {!connected ? (
                <button className="btn" onClick={() => handleWalletConnection(dispatch, connected)}>
                    Connect Wallet
                </button>
            ) : (
                <>
                    <div className="options">
                        <div className="balance">
                            <strong>
                                {balance > 0 ? (
                                    <>
                                        <small>Available rewards: </small> {balance.toFixed(5)}
                                        <small>BNB</small>
                                    </>
                                ) : (
                                    "No rewards available"
                                )}
                            </strong>
                        </div>
                    </div>
                    <div className="options">
                        <button
                            className="btn"
                            onClick={() => withdrawBalance(dispatch)}
                            disabled={balance > 0 ? false : true}
                        >
                            Withdraw
                        </button>
                    </div>
                    <div className="options">
                        <button className="btn" onClick={() => handleWalletConnection(dispatch, connected)}>
                            Disconnect
                        </button>
                    </div>
                </>
            )}
        </div>
    )
}

export default Home
