// auth
const START_WALLET_CONNECTION = "START_WALLET_CONNECTION"
const WALLET_CONNECTION_SUCCEEDED = "WALLET_CONNECTION_SUCCEEDED"
const WALLET_CONNECTION_FAILED = "WALLET_CONNECTION_FAILED"
const SET_ADDRESS = "SET_ADDRESS"
const SET_SIGNATURE = "SET_SIGNATURE"
const SET_BALANCE = "SET_BALANCE"
const DISCONNECT = "DISCONNECT"

export {
    START_WALLET_CONNECTION,
    WALLET_CONNECTION_SUCCEEDED,
    WALLET_CONNECTION_FAILED,
    SET_ADDRESS,
    SET_SIGNATURE,
    DISCONNECT,
    SET_BALANCE
}
