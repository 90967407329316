import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { applyMiddleware, createStore } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import { persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage"
import createSagaMiddleware from "redux-saga"
import MainLayout from "./components/main-layout"
import "./index.css"
import combinedReducer from "./reducers"
import reportWebVitals from "./reportWebVitals"

const persistConfig = {
    key: "root",
    storage
}
const persistedReducer = persistReducer(persistConfig, combinedReducer)

const sagaMiddleware = createSagaMiddleware()

const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)))

persistStore(store)

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<MainLayout />} />
                <Route path="*" element={<MainLayout />} />
            </Routes>
        </BrowserRouter>
    </Provider>,
    document.getElementById("root")
)

reportWebVitals()
