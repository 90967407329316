import WalletConnectProvider from "@walletconnect/web3-provider"
import { toast } from "react-toastify"
import Web3 from "web3"
import Web3Modal from "web3modal"
import MIN_ABI from "../abi/abi"
import { setBalance } from "../actions/authActions"

const SIGN_MESSAGE =
    "Please sign this message to verify the ownership of your address. This process will not use any BNB"

const providerOptions = {
    injected: {
        display: {
            name: "Metamask",
            description: "Connect with the provider in your Browser"
        },
        package: null
    },
    walletconnect: {
        display: {
            name: "WalletConnect",
            description: "Scan a QR code with your mobile wallet"
        },
        package: WalletConnectProvider,
        options: {
            rpc: {
                1: process.env.REACT_APP_BSC_NODE
            },
            bridge: process.env.REACT_APP_WALLET_CONNECT_BRIDGE
        }
    }
}

const web3Modal = new Web3Modal({
    network: "mainnet",
    cacheProvider: true,
    providerOptions
})

async function connectWallet() {
    try {
        const provider = await web3Modal.connect()
        const web3 = new Web3(provider)

        const address = (await web3.eth.getAccounts())[0]
        return address
    } catch (e) {
        disconnectWallet()
    }
    return null
}

async function requestSignature(address) {
    try {
        const provider = await web3Modal.connect()
        const web3 = new Web3(provider)
        if (provider._setAddresses) {
            provider._setAddresses([address.toLowerCase()])
        }

        const signature = await web3.eth.personal.sign(SIGN_MESSAGE, address)
        return signature
    } catch (e) {
        disconnectWallet()
    }
    return null
}

async function withdrawBalance(dispatch) {
    const provider = await web3Modal.connect()
    const web3 = new Web3(provider)
    const address = (await web3.eth.getAccounts())[0]
    try {
        const network = await web3.eth.net.getId()

        if (network !== 56) {
            toast.error("Wrong network selected, please make sure you're connected to the BSC network")
            return
        }

        const contract = new web3.eth.Contract(MIN_ABI, process.env.REACT_APP_REWARDS_TRACKER_ADDRESS)
        const withdraw = await contract.methods.withdraw()
        let data = withdraw.encodeABI()

        const gasPrice = await web3.eth.getGasPrice()
        const widthDrawamount = "0"
        const amount = Web3.utils.toWei(widthDrawamount, "ether")
        const estimatedGas = await contract.methods.withdraw().estimateGas({ from: address, value: String(amount) })

        const tx = {
            from: address,
            to: contract.options.address,
            gas: estimatedGas,
            gasPrice: gasPrice,
            data: data
        }

        await web3.eth.sendTransaction(tx)
        const balance = await getBalance(address)
        dispatch(setBalance(balance))
        toast.success("Your rewards have been withdrawn")
    } catch (e) {
        toast.error("Withdraw failed. Make sure your balance is not 0")
    }
}

async function getBalance(address) {
    try {
        const provider = new Web3.providers.WebsocketProvider(process.env.REACT_APP_BSC_NODE)

        const web3 = new Web3(provider)

        const contract = new web3.eth.Contract(MIN_ABI, process.env.REACT_APP_REWARDS_TRACKER_ADDRESS)

        const balance = await contract.methods.getTotalWalletAllocation(address).call()

        return balance / 10 ** 18
    } catch (e) {}
    return -1
}

function disconnectWallet() {
    web3Modal.clearCachedProvider()
}

export { connectWallet, disconnectWallet, requestSignature, withdrawBalance, getBalance }
