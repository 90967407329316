const MIN_ABI = [
    {
        inputs: [
            {
                internalType: "address",
                name: "wallet",
                type: "address"
            }
        ],
        name: "getTotalWalletAllocation",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256"
            }
        ],
        stateMutability: "view",
        type: "function"
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "identifier",
                type: "uint256"
            }
        ],
        name: "withdraw",
        outputs: [],
        stateMutability: "payable",
        type: "function"
    },
    {
        inputs: [],
        name: "withdraw",
        outputs: [],
        stateMutability: "payable",
        type: "function"
    }
]

export default { MIN_ABI }
