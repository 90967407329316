import {
    DISCONNECT,
    SET_ADDRESS,
    SET_BALANCE,
    SET_SIGNATURE,
    START_WALLET_CONNECTION,
    WALLET_CONNECTION_FAILED,
    WALLET_CONNECTION_SUCCEEDED
} from "../utils/actionConstants"

function startWalletConnection() {
    return {
        type: START_WALLET_CONNECTION
    }
}

function walletConnectionSucceeded() {
    return {
        type: WALLET_CONNECTION_SUCCEEDED
    }
}

function walletConnectionFailed(message, status) {
    return {
        type: WALLET_CONNECTION_FAILED,
        payload: {
            message,
            status
        }
    }
}

function setAddress(address) {
    return {
        type: SET_ADDRESS,
        payload: {
            address
        }
    }
}

function setSignature(signature) {
    return {
        type: SET_SIGNATURE,
        payload: {
            signature
        }
    }
}

function setBalance(balance) {
    return {
        type: SET_BALANCE,
        payload: {
            balance
        }
    }
}

function disconnect() {
    return {
        type: DISCONNECT
    }
}

export {
    startWalletConnection,
    walletConnectionSucceeded,
    walletConnectionFailed,
    setAddress,
    setSignature,
    setBalance,
    disconnect
}
